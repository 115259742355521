import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/default-page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "mon-setup",
      "style": {
        "position": "relative"
      }
    }}>{`Mon Setup`}<a parentName="h1" {...{
        "href": "#mon-setup",
        "aria-label": "mon setup permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "fill": "currentcolor",
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg"
        }}><g parentName="svg"><path parentName="g" {...{
              "stroke": "null",
              "d": "M22.93 8.784v-3.07h-4.073l.94-4.3L16.664.8l-1.097 4.913h-4.699l1.097-4.299L8.832.8 7.735 5.713H2.566v3.07H7.11l-1.41 6.142H1v3.071h4.073l-.94 4.3 3.133.613 1.096-4.913h4.7l-1.097 4.3 3.133.613 1.096-4.913h5.17v-3.07H16.82l1.41-6.142h4.698zm-9.399 6.141H8.832l1.566-6.141h4.7l-1.567 6.141z"
            }}></path></g></svg></a></h1>
    <p>{`Voici un aperçu de certains des logiciels et matériels que j'utilise actuellement au quotidien pour le développement web et d'autres activités. Je change assez souvent de configuration, cette page servira donc de point de repère pour les développeurs curieux.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b7cb932ce5330e1fc08d7d8b6762aae7/d2602/desk.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.90347490347492%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMFAgT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABZy4UUSUR/8QAGxAAAgEFAAAAAAAAAAAAAAAAAQIAAwQREyL/2gAIAQEAAQUCe5WCuWeDks5zsef/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAACAQUAAAAAAAAAAAAAAAAAEQECECExYf/aAAgBAQAGPwLFMvolF2bP/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARIVFhcf/aAAgBAQABPyHTIGHW8g3sQ9D9yZ5o9mRxP//aAAwDAQACAAMAAAAQUB//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPxBX/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/EGV//8QAGxABAQADAQEBAAAAAAAAAAAAAREAITGBQWH/2gAIAQEAAT8QCBYs0C+ODYM/VNYB0nuKSOmld7shl2oIwLz8MsEEAaz/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Setup de Maxime Richard avec Visual Studio Code et mon Terminal",
            "title": "Setup de Maxime Richard avec Visual Studio Code et mon Terminal",
            "src": "/static/b7cb932ce5330e1fc08d7d8b6762aae7/af659/desk.jpg",
            "srcSet": ["/static/b7cb932ce5330e1fc08d7d8b6762aae7/8356d/desk.jpg 259w", "/static/b7cb932ce5330e1fc08d7d8b6762aae7/bc760/desk.jpg 518w", "/static/b7cb932ce5330e1fc08d7d8b6762aae7/af659/desk.jpg 1035w", "/static/b7cb932ce5330e1fc08d7d8b6762aae7/51eb8/desk.jpg 1553w", "/static/b7cb932ce5330e1fc08d7d8b6762aae7/33042/desk.jpg 2070w", "/static/b7cb932ce5330e1fc08d7d8b6762aae7/d2602/desk.jpg 4032w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote">{`TLDR: Mon `}<a parentName="p" {...{
          "href": "https://github.com/zyhou/dotfiles",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`dotfiles`}</a>{` avec toutes mes configurations.`}</p>
    </blockquote>
    <p>{`En `}<abbr title="Operating System">{`OS`}</abbr>{`, j'ai `}<a parentName="p" {...{
        "href": "https://linuxmint.com/download.php",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Linux Mint 20`}</a>{` en verion MATE avec le théme `}<a parentName="p" {...{
        "href": "https://www.mate-look.org/p/1317409/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Arc-Darkest`}</a>{` et les icones `}<a parentName="p" {...{
        "href": "https://www.mate-look.org/p/1333421/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Arc-Darkest`}</a>{`. Étant joueur, j'utilise Windows 10 aussi bien pour jouer que pour coder, j'utilise le sous systéme Linux `}<a parentName="p" {...{
        "href": "https://docs.microsoft.com/en-us/windows/wsl/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`WSL 2.0`}</a>{` dans lequel j'ai installé `}<a parentName="p" {...{
        "href": "https://www.microsoft.com/en-us/p/ubuntu-2004-lts/9n6svws3rx71",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Ubuntu 20.4`}</a>{`.`}</p>
    <h2 {...{
      "id": "éditeur-de-code-et-terminal",
      "style": {
        "position": "relative"
      }
    }}>{`Éditeur de code et Terminal`}<a parentName="h2" {...{
        "href": "#%C3%A9diteur-de-code-et-terminal",
        "aria-label": "éditeur de code et terminal permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "fill": "currentcolor",
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg"
        }}><g parentName="svg"><path parentName="g" {...{
              "stroke": "null",
              "d": "M22.93 8.784v-3.07h-4.073l.94-4.3L16.664.8l-1.097 4.913h-4.699l1.097-4.299L8.832.8 7.735 5.713H2.566v3.07H7.11l-1.41 6.142H1v3.071h4.073l-.94 4.3 3.133.613 1.096-4.913h4.7l-1.097 4.3 3.133.613 1.096-4.913h5.17v-3.07H16.82l1.41-6.142h4.698zm-9.399 6.141H8.832l1.566-6.141h4.7l-1.567 6.141z"
            }}></path></g></svg></a></h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://code.visualstudio.com/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Visual Studio Code`}</a>{` est mon éditeur du moment. Mes settings se trouvent `}<a parentName="li" {...{
          "href": "https://github.com/zyhou/dotfiles/blob/master/config/vscode.json",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`ici`}</a>{`.`}</li>
      <li parentName="ul">{`Mon thème est l'excellentissime `}<a parentName="li" {...{
          "href": "https://marketplace.visualstudio.com/items?itemName=sdras.night-owl",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Night Owl`}</a>{` de `}<a parentName="li" {...{
          "href": "https://twitter.com/sarah_edo",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Sarah Drasner`}</a>{`. Il existe pour presque `}<a parentName="li" {...{
          "href": "https://github.com/sdras/night-owl-vscode-theme#dark-theme",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`tous les éditeurs`}</a>{`.`}</li>
      <li parentName="ul">{`En font, j'ai `}<a parentName="li" {...{
          "href": "https://www.jetbrains.com/lp/mono/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`JetBrains Mono`}</a>{` en version `}<a parentName="li" {...{
          "href": "https://github.com/ryanoasis/nerd-fonts/tree/master/patched-fonts/JetBrainsMono/Medium/complete",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Nerd Fonts`}</a>{`. Sur Windows, j'ai opté pour `}<a parentName="li" {...{
          "href": "https://github.com/microsoft/cascadia-code",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Cascadia Code`}</a>{` en version `}<a parentName="li" {...{
          "href": "https://github.com/ryanoasis/nerd-fonts/tree/master/patched-fonts/CascadiaCode/complete",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Nerd Fonts`}</a>{`.`}</li>
      <li parentName="ul">{`J'utilise `}<a parentName="li" {...{
          "href": "https://hyper.is/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Hyper`}</a>{` pour mon terminal sur Linux (`}<a parentName="li" {...{
          "href": "https://github.com/zyhou/dotfiles/blob/master/config/.hyper.js",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`config`}</a>{`) et `}<a parentName="li" {...{
          "href": "https://github.com/microsoft/terminal",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Terminal`}</a>{` sur Windows (`}<a parentName="li" {...{
          "href": "https://github.com/zyhou/dotfiles/blob/master/config/settings.json",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`config`}</a>{`).`}</li>
      <li parentName="ul">{`Plusieurs images sont disponibles `}<a parentName="li" {...{
          "href": "https://github.com/zyhou/dotfiles",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`ici`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "cli",
      "style": {
        "position": "relative"
      }
    }}>{`CLI`}<a parentName="h2" {...{
        "href": "#cli",
        "aria-label": "cli permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "fill": "currentcolor",
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg"
        }}><g parentName="svg"><path parentName="g" {...{
              "stroke": "null",
              "d": "M22.93 8.784v-3.07h-4.073l.94-4.3L16.664.8l-1.097 4.913h-4.699l1.097-4.299L8.832.8 7.735 5.713H2.566v3.07H7.11l-1.41 6.142H1v3.071h4.073l-.94 4.3 3.133.613 1.096-4.913h4.7l-1.097 4.3 3.133.613 1.096-4.913h5.17v-3.07H16.82l1.41-6.142h4.698zm-9.399 6.141H8.832l1.566-6.141h4.7l-1.567 6.141z"
            }}></path></g></svg></a></h2>
    <p>{`La configuration complète de tous ces outils est présente dans mon fichier `}<inlineCode parentName="p">{`.zshrc`}</inlineCode>{`, que vous pouvez trouver `}<a parentName="p" {...{
        "href": "https://github.com/zyhou/dotfiles/blob/master/config/.zshrc",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`ici`}</a>{`.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ohmyz.sh/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`oh my zsh > bash`}</a>{` qui apporte une liste de plugins intéressants.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/sharkdp/bat",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`bat > cat`}</a>{` avec night-owlish comme thème.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/ogham/exa",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`exa`}</a>{` pour améliorer l'affichage de `}<inlineCode parentName="li">{`ls`}</inlineCode>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/sharkdp/fd",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`fd > find`}</a>{` qui a des arguments plus simples à retenir que `}<inlineCode parentName="li">{`find`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Tous les matins, je lance `}<a parentName="li" {...{
          "href": "https://github.com/kamranahmedse/git-standup",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`git-standup`}</a>{` qui me rappelle les commits de la veille.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/junegunn/fzf",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`fzf > ctrl+r`}</a>{` pour trouver d'anciennes lignes de commandes.`}</li>
    </ul>
    <h2 {...{
      "id": "applications",
      "style": {
        "position": "relative"
      }
    }}>{`Applications`}<a parentName="h2" {...{
        "href": "#applications",
        "aria-label": "applications permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "fill": "currentcolor",
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg"
        }}><g parentName="svg"><path parentName="g" {...{
              "stroke": "null",
              "d": "M22.93 8.784v-3.07h-4.073l.94-4.3L16.664.8l-1.097 4.913h-4.699l1.097-4.299L8.832.8 7.735 5.713H2.566v3.07H7.11l-1.41 6.142H1v3.071h4.073l-.94 4.3 3.133.613 1.096-4.913h4.7l-1.097 4.3 3.133.613 1.096-4.913h5.17v-3.07H16.82l1.41-6.142h4.698zm-9.399 6.141H8.832l1.566-6.141h4.7l-1.567 6.141z"
            }}></path></g></svg></a></h2>
    <p>{`Vous pouvez trouver toutes les applications que j'installe lorsque je configure un nouveau ordinateur sous Linux grâce à mon `}<a parentName="p" {...{
        "href": "https://github.com/zyhou/dotfiles/blob/master/package.list",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`dotfiles`}</a>{`. Voici quelques applications (sans ordre particulier):`}</p>
    <ul>
      <li parentName="ul">{`Je suis un grand fan d'`}<a parentName="li" {...{
          "href": "https://albertlauncher.github.io/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Albert`}</a>{`.`}</li>
      <li parentName="ul">{`J'utilise `}<a parentName="li" {...{
          "href": "https://github.com/BoostIO/Boostnote",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Boostnote`}</a>{` pour ma prise de notes.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://flameshot.js.org/#/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Flameshot`}</a>{` qui permet de faire des screenshots facilement.`}</li>
      <li parentName="ul">{`Et `}<a parentName="li" {...{
          "href": "https://github.com/phw/peek",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Peek`}</a>{` pour créer des GIF.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://responsively.app/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Responsively App`}</a>{` pour développer plus rapidement sur une application responsive.`}</li>
    </ul>
    <h2 {...{
      "id": "extensions-chrome",
      "style": {
        "position": "relative"
      }
    }}>{`Extensions Chrome`}<a parentName="h2" {...{
        "href": "#extensions-chrome",
        "aria-label": "extensions chrome permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "fill": "currentcolor",
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg"
        }}><g parentName="svg"><path parentName="g" {...{
              "stroke": "null",
              "d": "M22.93 8.784v-3.07h-4.073l.94-4.3L16.664.8l-1.097 4.913h-4.699l1.097-4.299L8.832.8 7.735 5.713H2.566v3.07H7.11l-1.41 6.142H1v3.071h4.073l-.94 4.3 3.133.613 1.096-4.913h4.7l-1.097 4.3 3.133.613 1.096-4.913h5.17v-3.07H16.82l1.41-6.142h4.698zm-9.399 6.141H8.832l1.566-6.141h4.7l-1.567 6.141z"
            }}></path></g></svg></a></h2>
    <ul>
      <li parentName="ul">{`J'utilise `}<a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/json-formatter/bcjindcccaagfpapjjmafapmmgkkhgoa",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`JSON Formatter`}</a>{` pour avoir un meilleur rendu du JSON.`}</li>
      <li parentName="ul">{`Même si personne désactive Javascript, `}<a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/quick-javascript-switcher/geddoclleiomckbhadiaipdggiiccfje",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Quick Javascript Switcher`}</a>{` me permet de tester le `}<abbr title="Server Side Rendering">{`SSR`}</abbr>{` facilement.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/react-developer-tools/fmkadmapgofadopljbjfkapdkoienihi",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`React Developer Tools`}</a>{` est indispensable pour un développeur React.`}</li>
      <li parentName="ul">{`Comme pour React mais pour Redux, je vous présente `}<a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Redux DevTools`}</a>{`.`}</li>
      <li parentName="ul">{`Je me sers souvent de Trello pour gèrer des projets, deux extensions afin gagner du temps, `}<a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/trello-card-numbers/kadpkdielickimifpinkknemjdipghaf",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Trello Card Numbers`}</a>{` et `}<a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/points-for-trello-combine/elfbcdococbdgeaolelhgdghjgkkhndf",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Points for Trello - Combined Edition`}</a>{`.`}</li>
      <li parentName="ul">{`Pour la gestion de mes mots de passe, je fais confiance à `}<a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/lastpass-free-password-ma/hdokiejnpimakedhajhdlcegeplioahd",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`LastPass: Free Password Manager`}</a>{`.`}</li>
      <li parentName="ul">{`Pour recevoir les notifications de nouveaux mails sans avoir à laisser Gmail ouvert, j'utilise `}<a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/checker-plus-for-gmail/oeopbcgkkoapgobdbedcemjljbihmemj",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Checker Plus for Gmail™`}</a>{`.`}</li>
      <li parentName="ul">{`Malheureusement, j'ai un bloqueur de pub avec `}<a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/ublock-origin/cjpalhdlnbpafiamejdnhcphjbkeiagm",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`uBlock Origin`}</a>{`, je n'hésite pas à mettre mes sites favoris dans la liste des autorisations.`}</li>
      <li parentName="ul">{`J'adore regarder des compétitions sur Twitch, `}<a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/betterttv/ajopnjidmegmdimjlfnijceegpefgped",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`BetterTTV`}</a>{` est devenu essentiel.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/hover-zoom%20/pccckmaobkjjboncdfnnofkonhgpceea",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Hover Zoom+`}</a>{` permet de faire un zoom sur les images d'un site.`}</li>
    </ul>
    <h2 {...{
      "id": "équipements",
      "style": {
        "position": "relative"
      }
    }}>{`Équipements`}<a parentName="h2" {...{
        "href": "#%C3%A9quipements",
        "aria-label": "équipements permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "fill": "currentcolor",
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg"
        }}><g parentName="svg"><path parentName="g" {...{
              "stroke": "null",
              "d": "M22.93 8.784v-3.07h-4.073l.94-4.3L16.664.8l-1.097 4.913h-4.699l1.097-4.299L8.832.8 7.735 5.713H2.566v3.07H7.11l-1.41 6.142H1v3.071h4.073l-.94 4.3 3.133.613 1.096-4.913h4.7l-1.097 4.3 3.133.613 1.096-4.913h5.17v-3.07H16.82l1.41-6.142h4.698zm-9.399 6.141H8.832l1.566-6.141h4.7l-1.567 6.141z"
            }}></path></g></svg></a></h2>
    <ul>
      <li parentName="ul">{`Mon écran principal est un 24" 1080p `}<a parentName="li" {...{
          "href": "https://www.amazon.fr/gp/product/B00H3JIGHA",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Dell U2414H`}</a>{`.`}</li>
      <li parentName="ul">{`À ma gauche, j'ai un vieux Samsung 19" et à ma droite un pc portable `}<a parentName="li" {...{
          "href": "https://www.asus.com/fr/Commercial-Laptops/ASUSPRO-B8430UA/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`ASUSPRO B8430UA`}</a>{` qui vient de mon entreprise.`}</li>
      <li parentName="ul">{`Je suis assis sur une `}<a parentName="li" {...{
          "href": "https://secretlab.eu/fr/collections/omega-series#omega_2020-stealth",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Secretlab OMEGA`}</a>{`.`}</li>
      <li parentName="ul">{`Mon bureau est un Ikea simple noir (actuellement indisponible).`}</li>
      <li parentName="ul">{`J'ai monté ma tour en 2015, elle possède un INTEL Core i5 6600, une GeFORCE GTX 970 et une RAM HyperX Fury 8Go.`}</li>
      <li parentName="ul">{`Mon clavier mécanique est le `}<a parentName="li" {...{
          "href": "https://kbdfans.com/collections/fully-assembled-keyboard/products/fully-assembled-low-profile-case-tada68-keyboard",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`TADA68`}</a>{` 65% avec des Gateron Brown en switch.`}</li>
      <li parentName="ul">{`Une souris `}<a parentName="li" {...{
          "href": "https://www.amazon.fr/gp/product/B07GS6ZB7T",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Logitech G502`}</a>{` et un tapis `}<a parentName="li" {...{
          "href": "https://www.amazon.fr/gp/product/B01DIJIDKI",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Logitech G240`}</a>{`,`}</li>
      <li parentName="ul">{`Mon casque... dois-je vraiment le présenter ? Le `}<a parentName="li" {...{
          "href": "https://www.amazon.fr/gp/product/B076GT6XJ9",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`HyperX Cloud Alpha`}</a>{`.`}</li>
      <li parentName="ul">{`Mon support de casque est un `}<a parentName="li" {...{
          "href": "https://www.amazon.fr/gp/product/B072BV6X1Q",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`New Bee`}</a>{`.`}</li>
      <li parentName="ul">{`Une simple `}<a parentName="li" {...{
          "href": "https://www.amazon.fr/gp/product/B01BGBJ8Y0",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Logitech C270`}</a>{` pour la Webcam.`}</li>
      <li parentName="ul">{`Une lampe `}<a parentName="li" {...{
          "href": "https://www.ikea.com/fr/fr/p/tertial-lampe-de-bureau-gris-fonce-50355395/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`tertial`}</a>{` que j'accroche au bureau.`}</li>
      <li parentName="ul">{`Un téléphone portable `}<a parentName="li" {...{
          "href": "https://www.google.com/search?q=Xiaomi+Pocophone+F1",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Xiaomi Pocophone F1`}</a>{` avec une batterie externe `}<a parentName="li" {...{
          "href": "https://www.amazon.fr/gp/product/B01JIYWUBA",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Anker PowerCore`}</a>{` pour les voyages.`}</li>
      <li parentName="ul">{`En déplacement, j'opte pour le sac à dos `}<a parentName="li" {...{
          "href": "https://www.amazon.fr/gp/product/B004AM624C",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Case Logic DLBP114K`}</a>{` pour ranger mon matériel.`}</li>
      <li parentName="ul">{`Il est important de s'hydrater, `}<a parentName="li" {...{
          "href": "https://www.decathlon.fr/p/gourde-randonnee-500-bouchon-ouverture-rapide-1-litre-aluminium-bleu/_/R-p-109606",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`ma courde de un litre`}</a>{` n'est jamais loin de moi.`}</li>
      <li parentName="ul">{`Un figurine Funko Overwatch `}<a parentName="li" {...{
          "href": "https://www.amazon.fr/gp/product/B0797L9FK7",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Zarya`}</a>{`.`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`Tous les produits présentés sont des produits que j'ai achetés. Il ne s'agit pas de partenariats ni de liens d'affiliations.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      